body {
  position: relative;
  overflow-y: scroll;
  height: calc(100vh - 3.75rem);
  display: grid;
  grid-template-columns: minmax(4.5rem,300px) minmax(4.5rem,5fr) minmax(4.5rem,475px);
  font-family: Open Sans,-apple-system,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,Arial,sans-serif;
}
body:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background-image: url(./background.svg);
  background-size: 1024px;
  background-repeat: repeat;
  background-position: 50% 0;
}
#app {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
#cy {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 50;
}

h1 {
  font-size: 6em;
  width: 50vw;
  padding: 20px;
}

ul {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 150;
  list-style: none;
  padding: 0;
  display: flex;
}
li {
  padding-left: 1.3em;
}

li a {
  display: block;
  position: relative;
  width: 50px;
  height: 50px;
}

a:before {
  background-image: url(./icons/camera.svg);
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 50px;
  height: 50px;
}


a:hover, a:focus {
  background-color: #7abbff;
}

.variable {
  border: 1px solid red;
  background: #fff;
  z-index: 9999;
  padding: 0.15em;
  font-size: 12px;
  transform: translateY(100%);
  pointer-events: none;
  user-select: none;
}

.StoryStartNode {
  width: 551px;
  height: 72px;
  padding: 2px 2px 16px;
  opacity: 1;
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(16, 42, 67, 0.16);
  background-color: #fff;
}
.StoryStartNode__header {
  margin: 0 0 12px;
  padding: 6px 8px 6px 8px;
  border-radius: 6px;
  background-color: #eef1f4;
}
.StoryStartNode__header-text {
  text-align: left;
  color: #233a4e;
}
.StoryStartNode__text {
  text-align: left;
  color: #102a43;
}

